<template>
  <div id="app">
    <div class="container">
      <div class="content">
        <div class="fly" @click="copy('fly')"></div>
        <div class="tg" @click="copy('tg')"></div>
        <div class="aff" @click="goto()"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      platformUrl: '',
      affID: 5720,
    }
  },
  created () {
    this.getEnv();
  },
  computed: {
    isMobile() {
      const regexpText = 'iPhone|iPod|iPad|\biOS-universal(?:.+)Mac\b|\bAndroid(?:.+)Mobile\b|Android|(?:SD4930UR|\bSilk(?:.+)Mobile\b)|Silk|Windows Phone|\bWindows(?:.+)ARM\b|BlackBerry|BB10|Opera Mini|\b(CriOS|Chrome)(?:.+)Mobile|Mobile(?:.+)Firefox\b';
      const regexp = new RegExp(regexpText, 'i');
      return regexp.test(navigator.userAgent);
    },
  },
  methods: {
    copy(param) {
      let value;
      const el = document.createElement('textarea');
      el.readOnly = true;
      switch (param) {
        case 'fly':
          value = 'boli000'
          el.value = value;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          el.blur();
          document.body.removeChild(el);
          window.alert('复制成功');
          break;
        case 'tg':
          value = '@boli1818'
          el.value = value;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          el.blur();
          document.body.removeChild(el);
          window.alert('复制成功');
          break;
        default:
          break;
      }
    },
    async getEnv() {
      const VUE_APP_ENV_JSON = 'https://cdn.xinli18app.com/env.json';
      const res = await fetch(VUE_APP_ENV_JSON).then((res) => res.json());
      this.platformUrl = this.isMobile ? res.mobileWeb : res.platformUrl;
    },
    goto() {
      window.location = `${this.platformUrl}/?affi=${this.affID}`;
    },
  }
}
</script>

<style lang="scss">
@import '../src/style/_variables';

* {
  margin: 0;
  padding: 0;
}

body, html, #app {
  position: relative;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
}

.container {
  position: absolute;
  width: 1920px;
  height: 100vh;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  overflow-x: hidden;
}
// PC
.content {
  margin: 0 auto;
  position: relative;
  width: 1920px;
  height: 1000px;
  background: url('../src/assets/PC.jpg') center center / contain  no-repeat;
}

.fly, .tg {
  position: absolute;
  z-index: 1;
  width: 65px;
  height: 48px;
  background: rgba(0,0,0, 1);
  border-radius: 1em 0 0 1em;
  cursor: pointer;
  opacity: 0;
  &:hover {
    opacity: 0.2;
  }
}

.fly {
  top: 602px;
  left: 1330px;
}

.tg {
  top: 497px;
  left: 1330px;
}
.aff {
  position: absolute;
  z-index: 1;
  width: 270px;
  height: 75px;
  top: 705px;
  left: 1100px;
  cursor: pointer;
}
// 1200 ~ 768
@media (max-width: #{$pcrwd-breakpoint + 'px'}) {
  .content {
    width: vw(1920px);
    height: vw(1000px);
  }

  .fly, .tg {
    width: vw(65px);
    height: vw(48px);
  }

  .fly {
    top: vw(602px);
    left: vw(1330px);
  }

  .tg {
    top: vw(497px);
    left: vw(1330px);
  }

  .aff {
    top: vw(705px);
    left: vw(1100px);
    width: vw(270px);
    height: vw(75px);
  }
}
// H5
@media (max-width: #{$mblrwd-breakpoint + 'px'}) {

  .content {
    width: vwm(768px);
    height: vwm(2516px);
    background: url('../src/assets/H5.jpg') center center / contain no-repeat;
  }

  .fly, .tg {
    width: vwm(70px);
    height: vwm(50px);
  }

  .fly {
    top: vwm(1407px);
    left: vwm(640px);
  }

  .tg {
    left: vwm(300px);
    top: vwm(1407px);
  }

  .aff {
    top: vwm(1510px);
    left: 50%;
    transform: translateX(-50%);
    width: vwm(620px);
    height: vwm(175px);
  }
}
</style>
